<template>
  <div data-cy="news-page" class="news-page">
    <div class="news-cards">
      <NewsCard
        v-for="(item, index) in news"
        :id="item.id"
        :key="index"
        class="news-card"
        :image="item.previewImage"
        :date="item.date"
        :title="item.title"
        :preview-text="item.previewText"
        @click="onClickNewsCard(item.id)"
      />
    </div>

    <OurPagination
      v-if="isMoreThanOnePage"
      :current-page="paginationData.currentPage"
      :per-page="paginationData.perPage"
      :total="paginationData.total"
      @pageChange="onPageChange"
    />
  </div>
</template>

<script>
import { allRefProgramBlogsQuery } from "./gql/queries";
import ApiDatoсmsService from "@/services/apiDatoсms.service";
import DateService from "@/services/date.service";
import i18nService from "@/services/i18n.service";

import NewsCard from "./components/NewsCard";
import OurPagination from "@/components/table/OurPagination";

const CONTENT_WIDTH = 700;

export default {
  name: "News",

  components: {
    NewsCard,
    OurPagination,
  },

  data() {
    return {
      news: [],
      shownNewsNumber: 8,
      allNewsNumber: 0,
      currentPage: 1,
      perPage: 1,
      languages: {
        ua: "uk",
        pl: "pl",
        en: "en",
      },
    };
  },

  computed: {
    paginationData() {
      const total = Math.ceil(this.allNewsNumber / this.shownNewsNumber);

      return {
        currentPage: this.currentPage,
        perPage: this.perPage,
        total,
      };
    },

    isMoreThanOnePage() {
      return this.paginationData.total > 1;
    },
  },

  mounted() {
    this.$setBreadcrumbs();
    this.$setMaxContentWidth(CONTENT_WIDTH);
  },

  beforeDestroy() {
    this.$resetMaxContentWidth();
  },

  created() {
    this.getAllNews();
  },

  methods: {
    async getAllNews() {
      const skip = (this.currentPage - 1) * this.shownNewsNumber;
      const locale = this.languages[i18nService.getActiveLanguage()];

      const { allRefProgramBlog1s, _allRefProgramBlog1sMeta } = await ApiDatoсmsService.get(
        allRefProgramBlogsQuery,
        { first: this.shownNewsNumber, skip, locale }
      );

      this.allNewsNumber = _allRefProgramBlog1sMeta.count;

      this.news = allRefProgramBlog1s.map((news) => {
        const dateInTimestamp = DateService.getDateTimestamp(news?._updatedAt || news._createdAt);
        const date = DateService.getConverteredDate(dateInTimestamp, "dateWithFullMonth");

        return {
          ...news,
          date,
        };
      });
    },

    onClickNewsCard(id) {
      this.$router.push({ name: "NewsDetails", params: { id } });
    },

    onPageChange(page) {
      this.currentPage = page;
      this.getAllNews();
    },
  },
};
</script>

<style lang="scss" scoped>
.news-page {
  .news-card + .news-card {
    margin-top: 20px;
  }
}
</style>
