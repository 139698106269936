<template>
  <div data-cy="news-details-page" class="news-details-page">
    <KTCard class="markdown-body">
      <div v-if="news?.image" class="block-image" :style="bgImage" />
      <div class="content" v-html="renderedContent" />
    </KTCard>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { refProgramBlogQuery } from "./gql/queries";
import ApiDatoсmsService from "@/services/apiDatoсms.service";
import DateService from "@/services/date.service";
import i18nService from "@/services/i18n.service";

import KTCard from "@/components/card/KTCard";

const CONTENT_WIDTH = 850;

export default {
  name: "NewsDetails",
  components: { KTCard },

  data() {
    return {
      news: {},
      languages: {
        ua: "uk",
        pl: "pl",
        en: "en",
      },
    };
  },

  computed: {
    newsId() {
      return this.$route.params.id;
    },

    bgImage() {
      const path = this.news?.image.url || "";

      return `background-image: url(${path});`;
    },

    renderedContent() {
      return this.$options.filters.markdown(this.news.text);
    },
  },

  watch: {
    news: {
      handler: "onChangeNews",
      deep: true,
      immediate: false,
    },
  },

  mounted() {
    this.$setMaxContentWidth(CONTENT_WIDTH);
  },

  beforeDestroy() {
    this.$resetMaxContentWidth();
    this.SET_PAGE_TITLE("");
  },

  created() {
    this.getNews();
  },

  methods: {
    ...mapMutations(["SET_PAGE_TITLE"]),
    ...mapMutations("agent", ["SET_VIEWED_NEWS_ID"]),

    async getNews() {
      const locale = this.languages[i18nService.getActiveLanguage()];

      const { refProgramBlog1: news } = await ApiDatoсmsService.get(refProgramBlogQuery, {
        filter: { id: { in: this.newsId } },
        locale,
      });

      const dateInTimestamp = DateService.getDateTimestamp(news?._updatedAt || news._createdAt);
      const date = DateService.getConverteredDate(dateInTimestamp, "dateWithFullMonth");

      this.news = {
        ...news,
        date,
      };
    },

    onChangeNews() {
      this.SET_PAGE_TITLE(this.news?.title);
      document.title = this.news?.title;
      this.$setBreadcrumbs(["News"]);
      this.SET_VIEWED_NEWS_ID(this.news?.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.news-details-page {
  .markdown-body {
    color: #181c32;

    .block-image {
      width: 100%;
      height: 183px;
      background-size: cover;
      background-position: bottom;
      margin-bottom: 24px;
    }

    .content {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
