<template>
  <div class="news-card" @click="onClickMoreInfo">
    <div class="block-image" :style="bgImage" />
    <div class="content">
      <div class="date">{{ date }}</div>
      <div class="title">{{ title }}</div>
      <div class="preview-text">{{ previewText }}</div>
      <div class="button">
        <span class="text">{{ $t("button.moreInfo") }}</span>
        <span class="arrow">→</span>
      </div>
    </div>

    <div v-if="isNew" class="marker">{{ $t("title.new") }}</div>
  </div>
</template>

<script>
import { mapState } from "vuex";

const DEFAULT_IMAGE = require("@/assets/images/news-sample-image.png");

export default {
  name: "NewsCard",

  props: {
    id: {
      type: String,
      default: "",
    },

    image: {
      type: Object,
      default: () => {},
    },

    date: {
      type: String,
      default: "",
    },

    title: {
      type: String,
      default: "",
    },

    previewText: {
      type: String,
      default: "",
    },
  },

  computed: {
    ...mapState("agent", ["viewedNewsId"]),

    isNew() {
      return !this.viewedNewsId.includes(this.id);
    },

    bgImage() {
      const path = this.image?.url || DEFAULT_IMAGE;

      return `background-image: url(${path});`;
    },
  },

  methods: {
    onClickMoreInfo() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss" scoped>
.news-card {
  width: 100%;
  height: 150px;
  display: flex;
  background: #ffffff;
  border-radius: 8px;
  padding: 2px;
  cursor: pointer;
  position: relative;

  .block-image {
    min-width: 146px;
    height: 146px;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    background-position: center;
    background-size: contain;
    filter: grayscale(100%);
  }

  .content {
    max-width: 504px;
    padding: 18px 10px 18px 18px;
    display: flex;
    flex-direction: column;
    align-items: self-start;

    .date {
      margin-bottom: 8px;
      font-size: 12px;
      line-height: 14px;
      color: #6b7280;
    }

    .title {
      font-weight: 700;
      font-size: 14px;
      line-height: 139.5%;
      color: #181c32;
      margin-bottom: 4px;
    }

    .preview-text {
      overflow: hidden;
      font-size: 14px;
      line-height: 120%;
      align-items: center;
      margin-bottom: 8px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .button {
      width: auto;
      font-size: 12px;
      line-height: 14px;
      color: #6b7280;
      display: flex;
      align-content: center;
      align-items: center;

      .text {
        border-bottom: 1px solid #6b7280;
      }

      .arrow {
        padding-left: 2px;
        padding-bottom: 7px;
      }

      &:hover {
        color: #374151;
        .text {
          border-bottom: 1px solid #374151;
        }
      }
    }
  }

  .marker {
    position: absolute;
    right: 18px;
    top: 18px;
    color: red;
    font-weight: bold;
  }

  &:hover {
    .block-image {
      filter: none;
    }
  }
}
</style>
