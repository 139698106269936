import gql from "graphql-tag";

export const refProgramBlogQuery = gql`
  query refProgramBlog1($filter: RefProgramBlog1ModelFilter, $locale: SiteLocale) {
    refProgramBlog1(filter: $filter, locale: $locale) {
      id
      text
      title
      previewText
      image {
        url
        alt
      }
    }
  }
`;
