import gql from "graphql-tag";

export const allRefProgramBlogsQuery = gql`
  query allRefProgramBlog1S($first: IntType, $skip: IntType, $locale: SiteLocale) {
    allRefProgramBlog1s(first: $first, skip: $skip, locale: $locale) {
      id
      title
      previewText
      previewImage {
        url
        alt
      }
      _updatedAt
      _createdAt
    }
    _allRefProgramBlog1sMeta {
      count
    }
  }
`;
